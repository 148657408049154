import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import PropTypes from 'prop-types';
import './ImagesLighbox.scss';

const ImagesLightBox = ({ images, onClose }) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  const getCurrentImageSrc = () => {
    return images[photoIndex];
  };

  const getNextImageSrc = () => {
    return images[(photoIndex + 1) % images.length];
  };

  const getPreviousImageSrc = () => {
    const index = (photoIndex + images.length - 1) % images.length;
    return images[index];
  };

  const onMoveNextRequest = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  const onMovePrevRequest = () => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };

  const renderTotalImages = () => {
    return (
      <span>
        {photoIndex + 1} / {images.length}
      </span>
    );
  };

  return (
    <Lightbox
      mainSrc={getCurrentImageSrc()}
      nextSrc={getNextImageSrc()}
      prevSrc={getPreviousImageSrc()}
      onCloseRequest={onClose}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
      imageCaption={renderTotalImages()}
      wrapperClassName="LightBox"
    />
  );
};

ImagesLightBox.propTypes = {
  images: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImagesLightBox;
