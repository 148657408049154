import React, { useRef } from 'react';

import { getOriginalsScenesPageRoute } from '../../../services/navigation/navigation.service.routes';
import useInViewport from '../../../hook/useInViewport';
import useMoviesScenesData from '../../../hook/useMoviesScenesData';

import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';

const StudioResults = ({ hidePoster }) => {
  const containerRef = useRef(null);
  const isVisible = useInViewport(containerRef);

  const { videos, loading, error } = useMoviesScenesData({
    hookParams: { studios_id: 22309 },
    isVisible,
  });

  return (
    <MoviesScenesGrid
      title="NakedSword Originals"
      viewAllLink={{ text: 'View All', url: getOriginalsScenesPageRoute(1, 'Newest') }}
      data={videos}
      loading={loading}
      error={error}
      hideNavigation
      hidePoster={hidePoster}
      refProp={containerRef}
    />
  );
};

StudioResults.displayName = 'StudioResults';

export default StudioResults;
