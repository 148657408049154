import React, { useState } from 'react';
import { isUserLoggedIn } from '../../services/token-service/token.service';

import './AgeWarningOverlay.scss';

export const AGE_WARNING_OVERLAY = 'ageWarningOverlay';

const AgeWarningOverlay = () => {
  const [showOverlay, setShowOverlay] = useState(() => {
    const overlayDisplayed = parseInt(localStorage.getItem(AGE_WARNING_OVERLAY));
    let value = 0;

    if (overlayDisplayed !== 1 && !isUserLoggedIn()) {
      value = 1;
    } else if (isUserLoggedIn()) {
      value = 0;
    }

    return value;
  });

  const onCloseOverlay = () => {
    setShowOverlay(0);
    localStorage.setItem(AGE_WARNING_OVERLAY, 1);
  };

  if (!showOverlay) return null;

  const propertyId = process.env.REACT_APP_PROPERTY_ID;

  return (
    <div
      className="AgeWarningOverlay"
      style={{
        backgroundImage: `url('https://spotlight.nakedcdn.com/warning/${propertyId}_Enter-2bg.jpg')`,
      }}
    >
      <div className="Content">
        <p>
          By entering this site, you confirm you are at least 18 years of age, wish to view adult videos (pornography)
          and it is legal to view adult-related material in your country.
        </p>

        <button className="EnterBtn" onClick={onCloseOverlay}>
          ENTER
        </button>
        <button>
          <a href="https://google.com" className="warningExitLink">
            EXIT
          </a>
        </button>
      </div>
    </div>
  );
};

export default AgeWarningOverlay;
