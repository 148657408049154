import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import { urlSearchParamsPlaceholder } from '../../constants/navigation';
import { ROUTES } from '../../services/navigation/navigation.routes';

import './BaseSectionHeader.scss';

const BaseSectionHeader = ({ primaryText, secondaryLink, secondaryText, children }) => {
  const url =
    secondaryLink && secondaryLink?.includes(ROUTES.studios)
      ? `${secondaryLink}?${urlSearchParamsPlaceholder}`
      : secondaryLink;

  return (
    <div className="BaseSectionHeader">
      <div className="BaseSectionHeader-container">
        <div className="BaseSectionHeader-primary">{primaryText}</div>
        {children}
      </div>

      {secondaryLink && (
        <Link to={url} className="BaseSectionHeader-secondary">
          {secondaryText}
        </Link>
      )}
    </div>
  );
};

BaseSectionHeader.displayName = 'BaseSectionHeader';

BaseSectionHeader.propTypes = {
  primaryText: PropTypes.string,
  secondaryLink: PropTypes.string,
  secondaryText: PropTypes.string,
};

export default BaseSectionHeader;
