import React, { useEffect, useState } from 'react';
import { NotificationController } from '../../controllers/notifications-controller/notifications-controller';
import NotificationComponent from './NotificationComponent/NotificationComponent';

import './Notifications.scss';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const notificationsUpdated = NotificationController.notificationsUpdated.subscribe(onNotificationsUpdated);
    return () => {
      notificationsUpdated.unsubscribe();
    };
  }, []);

  const notificationMap = ({ data, id }) => {
    return <NotificationComponent data={data} notificationId={id} key={id} />;
  };

  const onNotificationsUpdated = (notifications) => {
    setNotifications([...notifications]);
  };

  return <div className="Notifications">{notifications.map(notificationMap)}</div>;
};

export default Notifications;
