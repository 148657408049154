import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { routes } from '../../../../services/navigation/navigation.service.routes';
import { isWeb } from '../../../../services/util-service/util.service';
import useInViewport from '../../../../hook/useInViewport';
import useMoviesScenesData from '../../../../hook/useMoviesScenesData';

import MoviesScenesGrid from '../../../../components/MoviesScenesGrid/MoviesScenesGrid';

const NewToday = ({ hidePoster }) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const isVisible = useInViewport(containerRef);

  const { videos, loading, error } = useMoviesScenesData({ isVisible });
  const slicedVideos = isWeb() ? videos : videos.slice(0, 4);

  return (
    <MoviesScenesGrid
      title={t('NewToday.mainTitle')}
      data={slicedVideos}
      loading={loading}
      error={error}
      viewAllLink={{ text: 'View All', url: routes.justAdded }}
      hideNavigation
      hidePoster={hidePoster}
      refProp={containerRef}
    />
  );
};

NewToday.displayName = 'NewToday';

export default NewToday;
