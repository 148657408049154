import axios from 'axios';

const urls = {
  search: '/frontend/search/home',
  searchLanding: '/frontend/search/landing',
};

export const searchHome = (value, section) => {
  const controller = new AbortController();
  return {
    result: axios.get(`${urls.search}?query=${value}&only=${section}`, {
      signal: controller.signal,
    }),
    controller,
  };
};

export const searchLandingResults = ({ query, page = 1, type, sortby = 'relevance', queryObject }) => {
  const controller = new AbortController();
  return {
    result: axios.get(`${urls.searchLanding}?query=${query}`, {
      signal: controller.signal,
      params: {
        page: page,
        object: type,
        sort_by: sortby,
        query_object: queryObject,
      },
    }),
    controller,
  };
};
