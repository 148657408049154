import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import { env } from './env';

const fallbackLng = ['en'];
const availableLanguages = ['en'];

// const isProduction = env.NODE_ENV === 'production';

i18n
  .use(Backend) // load translation using xhr -> see /public/locales.
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    // debug: !isProduction, // logs in console
    whitelist: availableLanguages,
    ns: ['translation', 'common'],
    defaultNS: 'translation',
    preload: availableLanguages,
    // interpolation: { // not needed for react
    //   escapeValue: false
    // },
  });

export default i18n;
