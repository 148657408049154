import { useEffect } from 'react';
import { getCookie } from '../services/util-service/util.service';

const gtagFunc = ({ data, removeUserId }) => {
  const userId = getCookie('user_id');
  const dataObj = data || {};
  dataObj.userId = userId;

  if (removeUserId) {
    delete dataObj.userId;
  }

  if (window.gtag) {
    window.dataLayer.push({ ...dataObj });
  }
};

// function for onLoad
export const useGtag = ({ data, removeUserId, skipTracking }) => {
  // path triggers rerender
  useEffect(() => {
    if (skipTracking) return;
    gtagFunc({ data, removeUserId });
    // eslint-disable-next-line
  }, [window.location.pathname]);
};

// function for events, button click...
export const onEventGtag = ({ data, removeUserId }) => {
  gtagFunc({ data, removeUserId });
};
