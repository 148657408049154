export const isToday = (date) => {
  const newDate = new Date(date);
  const today = new Date();
  const day = newDate.getDate();
  const month = newDate.getMonth();
  const year = newDate.getFullYear();
  const todayDay = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();
  return year === todayYear && month === todayMonth && day === todayDay;
};

export const convertSecondsToTime = (seconds) => {
  let minutes = Math.trunc(seconds / 60);
  seconds = seconds - minutes * 60;
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

export const convertRunTimeToSeconds = (runTime) => {
  const [h, m, s] = runTime.split(':').map((n) => parseInt(n));
  return h * 3600 + m * 60 + s;
};
