import axios from 'axios';

const urls = {
  ads: '/frontend/ads_config',
  feed: '/frontend/movies/feed',
  movies: '/frontend/movies',
};

export const getMovieDetails = (movieId, abortController) => {
  return axios.get(`${urls.movies}/${movieId}/details`, {
    signal: abortController.signal,
  });
};

export const getMovies = (params) => {
  return axios.get(urls.feed, { params });
};
