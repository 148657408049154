import { useState, useEffect } from 'react';

const useInViewport = (container = {}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!container.current) return;

    const callbackFunc = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setVisible(true);
      }
    };

    const rootElement = document.querySelector('.MainContent');
    const observerOptions = {
      root: rootElement || null,
      rootMargin: '0px 0px 60% 0px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(callbackFunc, observerOptions);

    try {
      const elem = container.current;
      if (elem) observer.observe(elem);
    } catch (err) {
      console.log('useInViewport', err);
    }

    return () => observer.disconnect();
  }, [container]);

  return visible;
};

export default useInViewport;
