import { navigate } from '@reach/router';
import axios from 'axios';
import { routes } from './services/navigation/navigation.service.routes';
import {
  cleanupToken,
  hasTokenExpired,
  getUserToken,
  getTokenRefreshPromise,
} from './services/token-service/token.service';
import { encrypt } from './services/encryption-service/encryption.service';
import { env } from './env';

const CHANGE_HEADER_TIME_SECONDS = 30;
let previousXheader = {
  init: false,
  data: {
    date: null,
    propertyId: env.REACT_APP_PROPERTY_ID,
  },
  header: null,
};
/**
 * Cleans up data
 */
export const cleanup = () => {
  cleanupToken();
  localStorage.setItem('previousRoute', window.location.pathname);
  navigate(routes.root, { replace: true });
};

/**
 * Returns object updated with CSRF token header
 *
 * @param {Object} config
 *
 * @returns {Object}
 */
const setCSRFTokenHeader = (config) => {
  config.headers['X-CSRF-TOKEN'] = getUserToken();

  return config;
};

/**
 * Returns object updated with Authorization header
 *
 * @param {Object} config
 *
 * @returns {Object}
 */
const setAuthorizationHeader = (config) => {
  config.headers['Authorization'] = `Bearer ${getUserToken()}`;

  return config;
};

const setCurrentXHeader = (date) => {
  previousXheader.data.date = date;
  const text = JSON.stringify(previousXheader.data);
  const identifier = encrypt(env.REACT_APP_PASSPHRASE, text);
  return Buffer.from(identifier).toString('base64');
};

const getXIndentHeader = () => {
  const date = Date.now();
  if (!previousXheader.init) {
    previousXheader.init = true;
    previousXheader.header = setCurrentXHeader(date);
  }
  const diffSeconds = Math.abs((previousXheader.data.date - date) / 1000);
  if (diffSeconds >= CHANGE_HEADER_TIME_SECONDS) {
    previousXheader.header = setCurrentXHeader(date);
  }
  return previousXheader.header;
};
/**
 * Returns object updated with site identifier header
 *
 * @param {Object} config
 *
 * @returns {Object}
 */
const setSiteIdentifierHeader = (config) => {
  config.headers['x-ident'] = getXIndentHeader();
  return config;
};

/**
 * If doNotSendCsrfToken is false adds CSRF token header else just returns unmodified config
 *
 * @param {Object} config
 *
 * @returns {Object}
 */
const handleCSRFToken = (config) => {
  const doNotSendCsrfToken = config?.headers.doNotSendCsrfToken;
  if (!doNotSendCsrfToken) {
    // Send CSRF token
    config = setCSRFTokenHeader(config);
  }
  if (config) {
    delete config.headers.doNotSendCsrfToken;
  }
  return config;
};

/**
 * Sets up axios request interceptor
 */
export const createRequestInterceptor = () => {
  const interceptor = axios.interceptors.request.use((config) => {
    let data;
    if (!config.headers.doNotAddUserTokenAuthorization) {
      config = setAuthorizationHeader(config);
    }
    config = setSiteIdentifierHeader(config);
    delete config.headers.doNotAddUserTokenAuthorization;
    if (hasTokenExpired()) {
      // User token expired
      cleanup();
    } else {
      // User token not expired
      data = Promise.resolve();
      if (config.url !== '/auth/refresh') {
        const refreshPromise = getTokenRefreshPromise();
        if (refreshPromise) {
          axios.interceptors.request.eject(interceptor);
          data = refreshPromise;
        }
      }
      data = data.then(handleCSRFToken.bind(null, config));
    }
    return data;
  });
};

/**
 * Sets up axios response interceptor
 */
export const createResponseInterceptor = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      return Promise.reject(error);
    },
  );
};
