import cloneDeep from 'lodash/cloneDeep';
import { env } from '../../env';

class DashboardCtrl {
  static instance;
  playerConfig = {};
  propertyAds = [];
  config = {};

  static getInstance() {
    if (DashboardCtrl.instance == null) {
      DashboardCtrl.instance = new DashboardCtrl();
    }

    return DashboardCtrl.instance;
  }

  getMovieSceneSpotlightData = () => {
    const instance = DashboardCtrl.getInstance();
    const { movie_spotlight_id, scene_spotlight_id, scene_spotlight_movie_id } = instance.config;
    const data = {};
    if (movie_spotlight_id) {
      data.movieSpotlight = {
        movieId: movie_spotlight_id,
      };
    }
    if (scene_spotlight_id) {
      data.sceneSpotlight = {
        movieId: scene_spotlight_movie_id,
        sceneId: scene_spotlight_id,
      };
    }

    return data;
  };

  getPlayerConfig = () => {
    const instance = DashboardCtrl.getInstance();
    return cloneDeep(instance.playerConfig);
  };

  setPlayerConfig = (config) => {
    const instance = DashboardCtrl.getInstance();
    if (config === null) {
      config = {
        config: [],
        configValues: {},
        id: env.REACT_APP_PROPERTY_ID,
        name: '',
      };
    }
    config.progressBarColors = {
      primary: config?.configValues?.playerColor || '#9f44b4',
      secondary: '#fff',
    };
    instance.playerConfig = config;
  };

  setWhiteLabelConfig = (config) => {
    const instance = DashboardCtrl.getInstance();
    instance.config = config;
  };

  getWhitelabelConfig = () => {
    const instance = DashboardCtrl.getInstance();
    return instance.config || {};
  };
}

export const DashboardController = DashboardCtrl.getInstance();
