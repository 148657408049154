import { useEffect, useState } from 'react';
import { getUserBannerSet } from '../services/banners-service/banners.service';
import useUserStateChange from './useUserStateChange';

const initialState = {
  data: [],
  info: {},
  loading: true,
  error: false,
};

function useBanners(setName, isVisible) {
  const [logged] = useUserStateChange();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (!isVisible) return;

    getUserBannerSet(setName, 1)
      .then((resp = {}) => {
        const { banners = [], banners_set = {} } = resp.data?.data || {};

        setState((prevState) => ({
          ...prevState,
          data: banners,
          info: banners_set,
        }));
      })
      .catch((error) => {
        setState((prevState) => ({ ...prevState, error: true }));
        console.error(error);
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, loading: false }));
      });

    return () => setState(initialState);
  }, [logged, setName, isVisible]);

  return state;
}

export default useBanners;
