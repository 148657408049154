import React, { Component } from 'react';
import withForwardedRef from '../../hoc/withForwardedRef/withForwardedRef';
import PropTypes from 'prop-types';

import { ModalController } from '../../controllers/modal-controller/modal.controller';

import './ModalDialog.scss';

class ModalDialog extends Component {
  clearLoading = () => {
    this.setState({
      loadingMessage: '',
    });
  };

  closeModal = () => {
    ModalController.removeModal();
  };

  getModalClasses = () => {
    const { className } = this.props;
    const classes = ['ModalDialog'];
    if (className) {
      classes.splice(0, 0, className);
    }

    return classes;
  };

  onCloseModal = () => {
    const { closeModal } = this.props;
    if (closeModal) {
      closeModal();
    } else {
      this.closeModal();
    }
  };

  renderTitle = () => {
    const { title } = this.props;
    let view = null;
    if (title) {
      view = <div className="ModalDialog-title">{title}</div>;
    }

    return view;
  };

  setLoading = (message) => {
    this.setState({
      loadingMessage: message,
    });
  };

  render() {
    const { actions, children, style } = this.props;
    const classes = this.getModalClasses();
    return (
      <div className={classes.join(' ')} style={style}>
        <div className="ModalDialog-primary">
          <div className="ModalDialog-secondary">
            {children}
            {actions}
          </div>
        </div>
        {this.renderTitle()}
        <i className="far fa-times-circle" onClick={this.onCloseModal} />
      </div>
    );
  }
}

ModalDialog.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  closeModal: PropTypes.func,
  forwardedRef: PropTypes.object,
  style: PropTypes.object,
};

export default withForwardedRef(ModalDialog);
