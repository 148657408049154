import React from 'react';
import { Link } from '@reach/router';
import { isExternalLink, isWeb } from '../../services/util-service/util.service';
import { routes } from '../../services/navigation/navigation.service.routes';
import { isUserLoggedIn } from '../../services/token-service/token.service';
import { DashboardController } from '../../controllers/dashboard-controller/DashboardController';

export default function NavigationLinks(props) {
  const { links = [], className } = props;
  const userLoggedIn = isUserLoggedIn();
  const { stars_page_enabled } = DashboardController.getWhitelabelConfig();

  if (!links || !Array.isArray(links)) return null;

  const renderNavLinks = links
    .map(item => {
      const {
        id,
        link,
        text,
        open_in_new_window,
        logged_in = true,
        logged_out = true,
        show_on_desktop = true,
        show_on_mobile = true
      } = item;

      let fullLink = link.startsWith('/') ? link : `/${link}`;
      const isExternal = isExternalLink(link);
      const isStarsPage = fullLink === routes.stars;

      if (isExternal) {
        fullLink = link;
      }

      if (
        (isStarsPage && !stars_page_enabled) ||
        (!show_on_desktop && isWeb()) ||
        (!show_on_mobile && !isWeb()) ||
        (!((userLoggedIn && logged_in) || (!userLoggedIn && logged_out)))
      ) {
        return null;
      }

      if (open_in_new_window || isExternal) {
        return (
          <a
            className={className}
            href={fullLink}
            rel={'noreferrer'}
            key={id}
            target={open_in_new_window ? '_blank' : '_self'}
          >
            {text}
          </a>
        );
      } else {
        return (
          <Link className={className} key={id} to={fullLink}>
            {text}
          </Link>
        );
      }
    })

  return renderNavLinks;
};
