export const setUserNATSCode = (nats) => {
  localStorage.setItem('nats_code', nats);
};

export const getUserNATSCode = () => {
  return localStorage.getItem('nats_code') || undefined;
};

export const getNatsUserData = () => {
  const userData = localStorage.getItem('fe-user-data');

  if (userData) {
    try {
      const userObj = JSON.parse(userData) || {};
      return userObj;
    } catch (e) {
      console.error('Error parsing user data:', e);
    }
  }
  return {};
};
