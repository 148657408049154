import React, { useState, useEffect } from 'react';

import { sessionExpires } from '../AvsPage.js';
import { ROUTES } from '../../../services/navigation/navigation.routes';
import { useGtag } from '../../../hook/useGtag.js';
import { useHtmlContentContext } from '../../../context/HtmlContentContext.js';
import RenderHtmlContent from '../../../components/RenderHtmlContent/RenderHtmlContent.js';

const AvsSuccess = () => {
  const { htmlContent } = useHtmlContentContext();
  const [countdown, setCountdown] = useState(17);

  useGtag({
    data: {
      event: 'page_view',
      page_title: 'AVS Success',
      page_location: window.location.href,
      page_path: window.location.pathname,
      event_label: 'success_page_loaded',
      event_category: 'age_verification',
      verification_stage: 'success_page_loaded',
    },
  });

  useEffect(() => {
    if (countdown === 0) {
      window.location.href = ROUTES.root;
      return;
    }

    const timerId = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [countdown]);

  return (
    <>
      <div className="Content">
        <div className="Heading">AGE VERIFICATION COMPLETE</div>

        <p>Thank you for verifying your age with our partner Yoti.</p>
        <p>
          <strong>Your status is: COMPLETE</strong>
        </p>
        <p>Your session is valid until: {sessionExpires()}</p>
        <p>
          <img
            src="https://spotlight.nakedcdn.com/nakedsword/img/yotilogo.jpg"
            width="138"
            height="63"
            alt="Yoti Logo"
          />
        </p>
        <p>
          If not automatically redirected in <span>{countdown}</span> seconds, please{' '}
          <a href={ROUTES.root} className="Link">
            click here
          </a>{' '}
          to proceed.
        </p>
      </div>
      <RenderHtmlContent htmlContent={htmlContent} dataLabel="support_service" />
    </>
  );
};

AvsSuccess.displayName = 'AvsSuccess';

export default AvsSuccess;
