import React, { useRef } from 'react';

import ImageLoadingSkeleton from '../../../components/ImageLoadingSkeleton/ImageLoadingSkeleton';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';
import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';
import ImageLoaderComponent from '../../../components/ImageLoaderComponent/ImageLoaderComponent';

import { isWeb } from '../../../services/util-service/util.service';
import { getNumberOneImage } from '../../../services/images-service/images.service';
import { showMovieDetailsPage } from '../../../services/navigation/navigation.service';
import TopTenImage from '../../../images/top-ten/Top10.svg';
import useInViewport from '../../../hook/useInViewport';
import useMoviesScenesData from '../../../hook/useMoviesScenesData';
import { SecondaryNavigation, navigationOptions } from '../../../constants/navigation';

import './TopTen.scss';

const TopTen = (props) => {
  const { excludeBanners } = props;

  const containerRef = useRef(null);
  const isVisible = useInViewport(containerRef);

  const { videos, loading, error } = useMoviesScenesData({
    videosType: navigationOptions.movies,
    videosSortType: SecondaryNavigation.topMovies,
    isVisible,
  });

  const numberOneMovie = videos[0] || {};
  const numberOfVideos = isWeb() ? videos.slice(1, 10) : videos.slice(0, 10);

  const renderNumberOneMovie = () => {
    if (loading) return <ImageLoadingSkeleton className="TopTenImageLoader" />;

    if (Object.keys(numberOneMovie).length !== 0) {
      const { movieId, title, titleNs, top_10_images = [] } = numberOneMovie;
      const movieName = titleNs || title;
      const { url, alt, width, height } = getNumberOneImage(top_10_images);

      if (!url) return null;

      const onImageClick = () => showMovieDetailsPage(movieId, movieName);

      const props = {
        url: TopTenImage,
        alt: alt || 'Number One',
        className: 'NumberOne',
        width: '400',
        height: '124',
      };

      if (isWeb()) {
        props.url = url;
        props.onClick = () => onImageClick();
        props.width = width;
        props.height = height;
      }

      return <ImageLoaderComponent {...props} />;
    }
  };

  const renderPromoBannerSet = () => {
    if (excludeBanners) return null;
    return <BannerSetViewFunc bannerName="promo" />;
  };

  const renderTourBannerSet = () => {
    if (excludeBanners) return null;
    return <BannerSetViewFunc bannerName="tour" />;
  };

  return (
    <div className="TopTen" ref={containerRef}>
      {renderPromoBannerSet()}
      {renderNumberOneMovie()}
      <MoviesScenesGrid data={numberOfVideos} loading={loading} error={error} hideNavigation showNumberIndicator />
      {renderTourBannerSet()}
    </div>
  );
};

TopTen.displayName = 'TopTen';

export default TopTen;
