import React from 'react';
import { Link } from '@reach/router';

export default function AvsNotFound({ linkToHome }) {
  return (
    <div style={{ textAlign: 'center', padding: '3em .5em' }}>
      <div>Page not found!</div>
      <br />
      <Link style={{ color: 'inherit' }} to={linkToHome}>
        Go back
      </Link>
    </div>
  );
}
