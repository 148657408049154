import { getImageByWidth } from '../images-service/images.service';

export const handleGallery = (gallery = [], reverse = false) => {
  if (gallery.length === 0) return [];

  const groupByMasterImageId = gallery.reduce((result, obj) => {
    (result[obj['master_image_id']] = result[obj['master_image_id']] || []).push(obj);
    return result;
  }, {});

  const images = [];
  Object.values(groupByMasterImageId).forEach((item) => {
    const imgObj = getImageByWidth(item);
    images.push(imgObj.url);
  });

  return reverse ? images.reverse() : images;
};
