import React, { useState, useRef } from 'react';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { displayErrorNotification } from '../../services/notification-service/notification.service';

const NewsletterSignUp = () => {
  const [email, setEmail] = useState('');
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const newsletterSubscribeParams = [
    'submitaction=3',
    'mlid=226702',
    'siteid=666711',
    'val_46732=NSFOOT',
    'tagtype=q2',
    'demographics=-1',
    'redirection=https://www.nakedsword.com/newsletterconfirm',
    'uredirection=',
    'welcome=',
    'double_optin=on',
    'append=',
    'update=',
    'activity=submit',
  ];

  const subscribeToNewsletter = (email) => {
    const url = `${process.env.REACT_APP_NEWSLETTER_URL}?${newsletterSubscribeParams.join('&')}&email=${encodeURIComponent(email)}`;
    return axios.post(url);
  };

  const onSubscribe = () => {
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailRegex.test(email)) {
        subscribeToNewsletter(email)
          .then(() => {
            setEmail(''); // Clear email field
            navigate(`/newsletterconfirm?email=${encodeURIComponent(email)}`);
          })
          .catch((err) => {
            console.error('Subscription error:', err);
            displayErrorNotification({
              duration: 3,
              message: t('LegalNotice.subscriptionError'),
              title: t('LegalNotice.error'),
            });
          });
      } else {
        displayErrorNotification({
          duration: 3,
          message: t('LegalNotice.usernameInvalid'),
          title: t('LegalNotice.invalidEntry'),
        });
        inputRef.current.focus();
      }
    } else {
      displayErrorNotification({
        duration: 3,
        title: t('LegalNotice.invalidEntry'),
      });
      inputRef.current.focus();
    }
  };

  return (
    <div className="NewsletterContainer d-flex align-center justify-center flex-wrap">
      <span>Newsletter Sign Up</span>
      <span className="GoldText">Special Offers, Free Stuff, Latest News & More!</span>
      <div className="InputContainer d-flex align-center">
        <input
          name="email"
          autoComplete="off"
          ref={inputRef}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('LegalNotice.enterEmail')}
        />
        <button type="button" onClick={onSubscribe}>
          {t('LegalNotice.subscribe')}
        </button>
      </div>
    </div>
  );
};
export default NewsletterSignUp;