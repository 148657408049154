// import React, { useEffect, useCallback } from 'react';
import { ModalController } from '../../../controllers/modal-controller/modal.controller';
import DealsImport from './DealsImport.js';
import { showMemberDealsPage } from '../../../services/navigation/navigation.service';

export const displayBenefitsModalDialog = () => {
  const numberOfDisplayedOffers = 6;
  const modal = (
    <div className="MemberDealsBgOverlay">
      <div className="MemberDealsModalPage">
        <button className="MemberDealsModalCLoseBtn" onClick={() => ModalController.removeModal()}>
          <i className="far fa-times-circle"></i>
        </button>

        <div className="DealsModalAccount" onClick={() => ModalController.removeModal()}>
          No Thanks. Take Me to My Nakedsword!
        </div>
        <h2 className="dealsModalHeader">SPECIAL OFFERS EXCLUSIVE TO OUR MEMBERS</h2>
        <DealsImport numberOfDisplayedOffers={numberOfDisplayedOffers} />
        <div className="MemberDealsModalSeeMoreBtnWrap">
          <button
            className="MemberDealsModalSeeMoreBtn cursor-pointer"
            onClick={() => {
              showMemberDealsPage();
              ModalController.removeModal();
            }}
          >
            SEE MORE OFFERS
          </button>
        </div>
      </div>
    </div>
  );
  ModalController.showModal(modal);
};


