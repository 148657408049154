import { Subject } from 'rxjs';

class MainDashboardCtrl {
  static instance;

  mainDashboardClick = new Subject();
  directorChangeNotifier = new Subject();
  movieChangeNotifier = new Subject();
  starChangeNotifier = new Subject();
  studioChangeNotifier = new Subject();
  themeChangeNotifier = new Subject();
  userTouchStartNotifier = new Subject();

  static getInstance() {
    if (MainDashboardCtrl.instance == null) {
      MainDashboardCtrl.instance = new MainDashboardCtrl();
    }

    return MainDashboardCtrl.instance;
  }

  notifyMainDashboardClick = (event) => {
    const instance = MainDashboardCtrl.getInstance();
    instance.mainDashboardClick.next(event);
  };

  notifyDirectorChange = () => {
    const instance = MainDashboardCtrl.getInstance();
    instance.directorChangeNotifier.next();
  };

  notifyMovieChange = () => {
    const instance = MainDashboardCtrl.getInstance();
    instance.movieChangeNotifier.next();
  };

  notifyStarChange = () => {
    const instance = MainDashboardCtrl.getInstance();
    instance.starChangeNotifier.next();
  };

  notifyStudioChange = () => {
    const instance = MainDashboardCtrl.getInstance();
    instance.studioChangeNotifier.next();
  };

  notifyThemeChange = () => {
    const instance = MainDashboardCtrl.getInstance();
    instance.themeChangeNotifier.next();
  };

  notifyUserTouchStart = () => {
    const instance = MainDashboardCtrl.getInstance();
    instance.userTouchStartNotifier.next();
  };
}

export const MainDashboardController = MainDashboardCtrl.getInstance();
