import axios from 'axios';

const urls = {
  addMovieToPlaylist: '/frontend/nats_user_playlist/attach',
  removeMovieFromPlaylist: '/frontend/nats_user_playlist/detach',
  natsPlaylists: '/frontend/nats_user_playlist',
  moviePlaylists: '/frontend/movie_playlist',
  scenePlaylists: '/frontend/scene_playlist',
};

export const getMoviePlaylist = (playlistId) => axios.get(`${urls.moviePlaylists}/${playlistId}`);

export const getScenePlaylist = (playlistId) => axios.get(`${urls.scenePlaylists}/${playlistId}`);

export const getAllPlaylists = () => axios.get(`${urls.natsPlaylists}`);

export const getSinglePlaylist = (id) => axios.get(`${urls.natsPlaylists}/${id}`);

export const reorderPlaylistItems = (id, data) => {
  const params = {
    reorder: data,
  };

  return axios.patch(`${urls.natsPlaylists}/reorder/${id}`, params);
};

export const updatePlaylist = ({ id, name }) => axios.patch(`${urls.natsPlaylists}/${id}?name=${name}`);

export const createPlaylist = ({ name }) => axios.post(`${urls.natsPlaylists}?name=${name}`);

export const deletePlaylist = ({ id }) => axios.delete(`${urls.natsPlaylists}/${id}`);

export const addMovieSceneToPlaylist = ({ playlistId, movieId, sceneId }) => {
  const params = {
    movies_id: movieId,
    scenes_id: sceneId,
  };
  return axios.patch(`${urls.addMovieToPlaylist}/${playlistId}`, { ...params });
};

export const removeMovieSceneFromPlaylist = ({ playlistId, movieId, sceneId }) => {
  const params = {
    movies_id: movieId,
    scenes_id: sceneId,
  };
  return axios.patch(`${urls.removeMovieFromPlaylist}/${playlistId}`, {
    ...params,
  });
};
