import React from 'react';

import './ImageLoadingSkeleton.scss';

const ImageLoadingSkeleton = ({ className }) => {
  const classes = ['ImageLoadingSkeleton'];

  if (className) classes.push(className);

  return (
    <div className={classes.join(' ')}>
      <div className="InnerSkeleton">
        <div className="ChildSkeleton"></div>
      </div>
    </div>
  );
};

export default ImageLoadingSkeleton;
