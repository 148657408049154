import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './ShowMeSortByNavigation.scss';

class ShowMeSortByNavigation extends Component {
  state = {
    showMe: undefined,
    sortBy: undefined,
  };

  subscriptions = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    let state = null;
    const { sortByInitialValue, showMeInitialValue } = nextProps;
    if (showMeInitialValue !== prevState.showMe || sortByInitialValue !== prevState.sortBy) {
      state = {
        showMe: showMeInitialValue,
        sortBy: sortByInitialValue,
      };
    }

    return state;
  }

  constructor(props) {
    super(props);
    const { showMeInitialValue, sortByInitialValue, onValueChange } = props;
    Object.assign(this.state, {
      showMe: showMeInitialValue,
      sortBy: sortByInitialValue,
    });
    if (onValueChange) {
      this.subscriptions.onValueChange = onValueChange.subscribe(this.onValueChange.bind(this));
    }
  }

  componentWillUnmount() {
    for (const key in this.subscriptions) {
      this.subscriptions[key].unsubscribe();
    }
  }

  onShowMeClick = (showMe) => {
    this.setState({ showMe });
    this.props.onShowMeClick(showMe);
  };

  onSortByClick = (sortBy) => {
    this.setState({ sortBy });
    this.props.onSortByClick(sortBy);
  };

  onValueChange = (data) => {
    this.setState(data);
  };

  renderItem = (selectedValue, onClick, key, hideShowMeSeparators, { name, value }, index) => {
    const classes = ['Item'];
    if (selectedValue && selectedValue.toLowerCase() === value.toLowerCase()) {
      classes.push('Selected');
    }
    return (
      <div className={classes.join(' ')} onClick={onClick.bind(this, value)} key={`${key}-${index}`}>
        {name}
      </div>
    );
  };

  renderNavigationItem = (selectedValue, onClick, key, hideSeparator, result, showMeOption, index, array) => {
    const { name, value } = showMeOption;
    const classes = ['Item'];
    if (key === 'showMe' && name.length === 1) {
      classes.push('LetterItem');
    }
    let isSelected = false;
    if (selectedValue) {
      if (value.toLowerCase) {
        isSelected = selectedValue.toLowerCase() === value.toLowerCase();
      } else {
        isSelected = selectedValue.toLowerCase() === name.toLowerCase();
      }
    }
    if (isSelected) {
      classes.push('Selected');
    }
    const additionalData = isSelected
      ? {}
      : {
          onClick: onClick.bind(this, value),
        };
    result.push(
      <div className={classes.join(' ')} {...additionalData} key={`${key}-${index}`}>
        {name}
      </div>,
    );
    if (!hideSeparator && index !== array.length - 1) {
      const separatorKey = `${key}-separator-${name}`;
      result.push(<div className="Separator" key={separatorKey} />);
    }

    return result;
  };

  renderShowMe = () => {
    let view = null;
    const { showMeItems } = this.props;
    if (showMeItems) {
      const { hideShowMeSeparators, t } = this.props;
      const showMeClasses = ['Section'];
      if (hideShowMeSeparators) {
        showMeClasses.push('SeparatorsHidden');
      }
      view = (
        <div className={showMeClasses.join(' ')}>
          <div className="Label">{t('ShowMeSortByNavigation.showMe')}</div>
          {this.renderShowMeItems()}
        </div>
      );
    }

    return view;
  };

  renderShowMeItems = () => {
    const { hideShowMeSeparators, showMeItems } = this.props;
    const { showMe } = this.state;
    return showMeItems.reduce(
      this.renderNavigationItem.bind(this, showMe, this.onShowMeClick, 'showMe', hideShowMeSeparators),
      [],
    );
  };

  renderSortBy = () => {
    let view = null;
    const { sortByItems } = this.props;
    if (sortByItems) {
      const { hideSortBySeparators, t } = this.props;
      const sortByClasses = ['Section'];
      if (hideSortBySeparators) {
        sortByClasses.push('SeparatorsHidden');
      }
      view = (
        <div className={sortByClasses.join(' ')}>
          <div className="Label">{t('ShowMeSortByNavigation.sortBy')}</div>
          {this.renderSortByItems()}
        </div>
      );
    }

    return view;
  };

  renderSortByItems = () => {
    const { hideSortBySeparators, sortByItems } = this.props;
    const { sortBy } = this.state;
    return sortByItems.reduce(
      this.renderNavigationItem.bind(this, sortBy, this.onSortByClick, 'sortBy', hideSortBySeparators),
      [],
    );
  };

  render() {
    return (
      <div className="ShowMeSortByNavigation">
        {this.renderShowMe()}
        {this.renderSortBy()}
      </div>
    );
  }
}

ShowMeSortByNavigation.propTypes = {
  hideShowMeSeparators: PropTypes.bool,
  hideSortBySeparators: PropTypes.bool,
  onShowMeClick: PropTypes.func,
  onSortByClick: PropTypes.func,
  onValueChange: PropTypes.object,
  showMeInitialValue: PropTypes.string,
  showMeItems: PropTypes.array,
  sortByInitialValue: PropTypes.string,
  sortByItems: PropTypes.array,
};

export default withTranslation()(ShowMeSortByNavigation);
