import React, { Component } from 'react';
import Arrow from '../Arrow/Arrow';

import './DropdownSelect.scss';

class DropdownSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.dropdownSelectRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownSelectRef && !this.dropdownSelectRef.current.contains(event.target)) {
      if (this.state.show) this.setState({ show: false });
    }
  };

  onDropdownClick = () => {
    const { clearSearchValue } = this.props;
    const { show } = this.state;
    this.setState({ show: !show }, clearSearchValue());
  };

  renderOption = (option, index, onChangeSearchSection) => {
    const { name } = option;

    return (
      <li key={`${this.props.keyToUse}-${index}`} className="Option" onClick={() => onChangeSearchSection(option)}>
        <span>{name}</span>
      </li>
    );
  };

  render() {
    const { show } = this.state;
    const { options, onChangeSearchSection, selectedDropdownValue } = this.props;

    return (
      <div
        ref={this.dropdownSelectRef}
        className={`DropdownSelect cursor-pointer ${show ? 'isOpen' : ''}`}
        onClick={this.onDropdownClick}
      >
        <span className="SelectedTerm">{selectedDropdownValue?.name}</span>
        <Arrow show={show} />
        <ul className="DropdownSelectContent">
          {options.map((option, index) => this.renderOption(option, index, onChangeSearchSection))}
        </ul>
      </div>
    );
  }
}

export default DropdownSelect;
