import {
  SecondaryNavigation,
  navigationOptions,
  upperCaseFirstLetter,
  urlSearchParamKeys,
} from '../constants/navigation';

export const handleHistory = (data, callback, pushToHistory) => {
  const { page, sort, type } = data;

  const params = new URLSearchParams(window.location.search);
  params.set(urlSearchParamKeys.content, upperCaseFirstLetter(type));
  params.set(urlSearchParamKeys.sort, upperCaseFirstLetter(sort));
  params.set(urlSearchParamKeys.page, upperCaseFirstLetter(page));
  const url = `${window.location.pathname}?${params.toString()}`;

  if (pushToHistory.current) {
    window.history.pushState({ page, sort, type }, '', url);
  }

  window.addEventListener('popstate', function (event) {
    pushToHistory.current = false;
    const { page = 1, sort = SecondaryNavigation.Newest, type = navigationOptions.scenes } = event.state || {};
    callback(page, sort, type);
  });
  pushToHistory.current = true;
};
