import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

import { LoadingController } from '../../controllers/loading-controller/loading.controller';

import './LoadingMask.scss';

class LoadingMask extends Component {
  state = {
    maskVisible: false,
    text: 'text',
  };
  subscription;

  componentDidMount() {
    this.subscription = LoadingController.displayLoadingMask.subscribe(this.displayMask);
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  displayMask = (data) => {
    this.setState({
      maskVisible: data.displayMask,
      text: data.text,
    });
  };

  render() {
    const classes = ['LoadingMask'];
    if (this.state.maskVisible) {
      classes.push('Visible');
    }

    return (
      <div className={classes.join(' ')}>
        <Loader type="ThreeDots" height="200" width="200" />
        {/*{this.state.text}*/}
      </div>
    );
  }
}

export default LoadingMask;

export const LoadingText = () => <div className="SuspenseLoader">Loading...</div>;
