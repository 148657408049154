import { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

// MainDashoboard.js controls on which page to display portal, MobileSecondaryNavigation
export const Portal = ({ children, el = 'div' }) => {
  const containerRef = useRef(document.createElement(el));
  const container = containerRef.current;

  useEffect(() => {
    const mobileBottomNavigation = document.getElementById('secondaryNavigation');

    if (container && mobileBottomNavigation) {
      container.classList.add('SecondaryActions');
      mobileBottomNavigation.appendChild(container);
    }

    return () => {
      if (mobileBottomNavigation) {
        mobileBottomNavigation.removeChild(container);
      }
    };
  }, [container]);

  return createPortal(children, container);
};
